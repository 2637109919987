// import i18n from '@/i18n'
import { mapActions, mapGetters } from 'vuex'
import BreadcrumbComponent from '@/components/commons/breadcrumb/BreadcrumbComponent.vue'
import CurrentStateTable from '@/components/map-report-trailers/table/CurrentStateTable.vue'
import mapReportApi from '@/api/mapReport.api.js'
import i18n from '@/i18n'
import AutocompleteComponent from '@/components/commons/autocomplete/AutocompleteComponent.vue'
import service from '@/middleware'

export default {
  name: 'MapReportComponent',
  components: {
    BreadcrumbComponent,
    CurrentStateTable,
    AutocompleteComponent
  },
  props: {
  },
  data: () => ({
    updateProcess: null,
    internalMinutesToRefresh: 10,
    mapConfiguration: [],
    markers: [],
    settingDisabled: false,
    tableModel: {
      showSelector: false,
      // Sólo muestra datos de trailers
      type: 'TRAILER',
      reportFilters: [],
      loaded: false
    },
    tabsCurrentState: [
      {
        id: 'tab-map',
        name: i18n.t('map.map')
      },
      {
        id: 'tab-table',
        name: i18n.t('currentStatusReport.title')
      }
    ],
    selectedTabCurrentState: 'tab-map',
    breadcrumbButtomsCurrentState: [],
    mapButtons: [],
    middleware: service.http
  }),
  computed: {
    ...mapGetters([
      'getFirebaseToken'
    ]),
    minutesToRefresh () {
      return this.internalMinutesToRefresh * 60 * 1000
    }
  },
  created () {
    // Agrega boton para configurar filtros
    this.breadcrumbButtomsCurrentState.push(
      {
        id: 'setting-btn',
        icon: 'settings',
        show: () => { return true },
        disable: this.disableSelectorButton.bind(this),
        function: this.selector.bind(this)
      })
  },
  async mounted () {
    if (!this.tableModel.loaded) {
      this.tabSelectedCurrentState('tab-table')
    }

    // Setea el lenguaje de los componentes de Vuetify
    this.$vuetify.lang.current = this.$i18n.locale
    this.getData()
    console.log('map report component')
    this.beginUpdateProcess()
  },
  beforeDestroy () {
    if (this.updateProcess != null) {
      clearInterval(this.updateProcess)
    }
  },
  methods: {
    ...mapActions({
      'showSnackbar': 'snackbar/showSnackbar',
      'closeSnackbar': 'snackbar/closeSnackbar'
    }),
    beginUpdateProcess () {
      this.updateProcess = setInterval(() => this.updateMap(), this.minutesToRefresh)
    },
    disableSelectorButton () {
      return this.settingDisabled
    },
    selector () {
      this.tableModel.showSelector = !this.tableModel.showSelector
    },
    getData () {
      const mustFilter = this.tableModel.reportFilters.length > 0
      const thingsSelected = mustFilter ? this.tableModel.reportFilters[0].selectedData.map(thing => thing.id) : []
      mapReportApi.getMapReport(this.tableModel.type, thingsSelected).then(response => {
        this.mapConfiguration.cleanAndUpdate([response.data.map])
        response.data.things.sort(function sortFunction (a, b) {
          const nameA = a.thingName.toLowerCase()
          const nameB = b.thingName.toLowerCase()
          if (nameA < nameB) {
            return -1
          } else if (nameA > nameB) {
            return 1
          } else {
            return 0
          }
        })
        this.markers.cleanAndUpdate(response.data.things)
      })
    },
    centerMap (selectedItem) {
      console.log(selectedItem)
      if (selectedItem.position) {
        this.$refs.MapReportComponent.centerMap(selectedItem.position)
      }
    },
    change () {
      const mapRef = this.$refs['MapReportComponent']
      if (mapRef) mapRef.change()
    },
    tabSelectedCurrentState (id) {
      this.selectedTabCurrentState = id
      if (id === 'tab-map') {
        this.change()
      }
    },
    updateMap () {
      this.getData()
    },
    // Para implementar una vez se guarden las configuraciones de los filtros (idMap, groupName, references, mapsId)
    mapResetFilter () {
    }
  },
  watch: {
    'tableModel.reportFilters': {
      handler: function () {
        this.updateMap()
      },
      deep: true
    }
  }
}
