import { filterRulesMapped } from '@/tools/filterRules'
import mapReportApi from '@/api/mapReport.api'
import { geoReferenceApi, cancelGeoData, cancelPOI } from '@/api/geoReference.api'
import { mapMutations, mapActions } from 'vuex'
import { SnackbarStyle } from '@/constants/constants'
import { dateSortFunction } from '@/tools/functions'
import AddPOIDialogComponent from '@/components/commons/point-of-interest/AddPOIDialog.vue'
import SelectorComponent from '@/components/commons/selector/SelectorComponent.vue'
import { TimeRanges, CustomTimeRangeTypes } from '@colven/common-domain-lib/lib'
import thingApi from '@/api/thing.api'
import reportStorageApi from '@/api/report-storage.api'
import i18n from '@/i18n'
import { mapCurrentStateService } from '../../../business/mapCurrentStateService'

export default {
  name: 'CurrentStateTable',
  components: {
    AddPOIDialogComponent,
    SelectorComponent
  },
  data () {
    return {
      reportLoaded: false,
      intervalId: null,
      tableHeaders: [],
      tableFilterRules: {},
      tableData: [],
      tableDataKey: '_id',
      tableComponentButtons: [],
      queryPageSize: 100,
      queryPageNumber: 1,
      pageTotal: undefined,
      loadingTable: false,
      geoDataIndex: -1,
      poiDataIndex: -1,
      maxRadius: 20,
      loadingSpinner: false,
      selectorModel: {
        showClose: true,
        filters: [
          {
            id: 'filter-thing',
            name: i18n.t('headers.thing'),
            show: false,
            disabled: false,
            showDialog: false,
            singleSelect: false,
            data: [],
            selectedData: [],
            selectAction: undefined
          }
        ],
        selects: [],
        disableGetReportButton: true,
        showDateAndTimeSelector: false,
        selectedDateAndTimeRange: TimeRanges.LAST_WEEK,
        selectedDateAndTimeRangeCustomType: CustomTimeRangeTypes.DATE,
        sinceDate: null,
        sinceTime: null,
        toDate: null,
        toTime: null,
        customDateTimeValidForm: false,
        autoReport: false,
        autoReportMinutes: 10
      },
      requestCountGeoData: 0,
      requestCountPOI: 0,
      rowActions: [
        {
          id: 1,
          name: i18n.t('poi.create'),
          color: 'blue',
          icon: 'push_pin'
        }
      ],
      addPOILat: 0,
      addPOILong: 0,
      showAddPOI: false,
      // se activa cuando las referencias fueron resueltas
      referenceResolved: false,
      // se activa cuando las ubicaciones fueron resueltas
      ubicationResolved: false
    }
  },
  computed: {
  },
  created () {
    // instancia del timeout para completar los datos de las direcciones
    this.$_debounceTimeoutId = null
    // se agregan los botones para obtener las georeferencias y puntos de interes a la tabla
    this.tableComponentButtons.push(
      {
        id: 'get-geo-data',
        tooltip: i18n.t('poi.loadLocations'),
        icon: 'explore',
        action: this.fireGeoDataRequests.bind(this),
        selectedData: false
      })
    this.tableComponentButtons.push(
      {
        id: 'get-POI',
        tooltip: i18n.t('poi.load'),
        icon: 'push_pin',
        action: this.firePOIRequests.bind(this),
        selectedData: false
      })
    // Si hay query params, los setea para incluirlos en la llamada al backend, de lo contrario, busca todas las reglas de vinculación
    if (this.$route.query) {
      this.queryPageSize = this.$route.query.pageSize ? Number(this.$route.query.pageSize) : 100
      this.queryPageNumber = this.$route.query.pageNumber ? Number(this.$route.query.pageNumber) : 1
    }
    // Valida que los qery params sean correctos y completos
    if ((this.queryPageSize && this.queryPageSize <= 0) || (this.queryPageNumber && this.queryPageNumber <= 0) || (this.queryPageNumber && !this.queryPageSize) || (!this.queryPageNumber && this.queryPageSize)) {
      this.showSnackbar({ visible: true, text: this.$t('error.invalidQueryParams'), timeout: 6000, style: SnackbarStyle.ERROR })
      this.queryPageSize = 100
      this.queryPageNumber = undefined
    }
  },
  mounted () {
    // Setea el lenguaje de los componentes de Vuetify
    this.$vuetify.lang.current = this.$i18n.locale
    this.initializeSelector()
    // Verifico si hay una clave de reporte como parámetro y cargo ese reporte
    const reportKey = this.$route.query.key
    this.getData(reportKey)
    this.reportLoaded = true
  },
  beforeDestroy () {
    this.closeReportDefer()
    // Cancelar las request pendientes
    if (this.requestCountGeoData > 0) {
      cancelGeoData('cancel geo-data requests')
    }
    if (this.requestCountPOI > 0) {
      cancelPOI('cancel poi requests')
    }
    // limpiar el timeout
    clearTimeout(this.$_debounceTimeoutId)
    this.$_debounceTimeoutId = null
  },
  methods: {
    ...mapActions({
      'showSnackbar': 'snackbar/showSnackbar',
      'closeSnackbar': 'snackbar/closeSnackbar'
    }),
    ...mapMutations('dialog', {
      openDialog: 'openDialog',
      closeDialog: 'closeDialog'
    }),
    ...mapMutations('reportDefer', {
      showReportDefer: 'showReportDefer',
      closeReportDefer: 'closeReportDefer',
      commitVisible: 'commitVisible'
    }),
    ...mapMutations('app', {
      setEntitySelectorItems: 'setEntitySelectorItems',
      setEntityConfiguration: 'setEntityConfiguration',
      resetEntitySelector: 'resetEntitySelector',
      setSelectedItem: 'setSelectedItem'
    }),
    selector () {
      this.updateModel(!this.value.showSelector, this.selectorModel.filters)
    },
    /**
     * Resize de los split panel
     */
    resize () {
    },
    /**
     * Click en una fila
     * @param {*} data
     */
    clickRow () {
    },
    /**
     * Refrescar la data
     */
    refreshTable () {
      if (this.loadingSpinner) this.commitVisible({ visible: true })
      this.loadingTable = true
      this.getDataNotDeferred(true)
    },
    /**
     * Se ejecuta cuando cambian los datos de la tabla (por búsqueda, filtrado, agregar/quitar columnas, etc.)
     * @param {*} newData
     */
    dataChangeEventHandler () {
    },
    getReport (eventData) {
      this.loadingTable = true
      if (eventData.autoReport) {
        if (this.loadingSpinner) this.commitVisible({ visible: true })
        this.getDataNotDeferred()
        clearInterval(this.intervalId)
        const that = this
        this.intervalId = setInterval(
          function () {
            that.getDataNotDeferred(true)
          }, eventData.autoReportMinutes * 60 * 1000)
      } else {
        const thingRequest = this.selectorModel.filters[0].selectedData
        mapReportApi.getCurrentStateDeferred(this.value.type, thingRequest, this.$route.path)
        this.showReportDefer({ updateFunction: this.getData.bind(this) })
      }
      this.updateModel(false, this.selectorModel.filters)
    },
    /**
     * TO DO: analizar la implementación del paginado
     */
    getData (reportKey) {
      if (reportKey) {
        this.loadingTable = true
        reportStorageApi.getReport(reportKey)
          .then(async response => {
            const processedData = await mapCurrentStateService.processReportData(response.data.data)
            this.tableHeaders.cleanAndUpdate(processedData.headers)
            this.setSortFunctions()
            this.setTableFilterRules()

            this.tableData.cleanAndUpdate(processedData.data)
            const loadedThings = response.data.filters
            if (loadedThings.length > 0) {
              this.selectorModel.filters[0].selectedData = loadedThings
              this.thingFilterCallback()
            }
            this.updateModel(false, this.selectorModel.filters)
            this.loadingTable = false
            if (this.requestCountGeoData > 0) {
              cancelGeoData('cancel geo-data requests')
            }
            if (this.requestCountPOI > 0) {
              cancelPOI('cancel poi requests')
            }
            this.geoDataIndex = -1
            this.poiDataIndex = -1
            this.maxRadius = 20
            this.requestCountGeoData = 0
            this.requestCountPOI = 0
            // limpiar el timeout
            clearTimeout(this.$_debounceTimeoutId)
            this.$_debounceTimeoutId = null
            // si ya se han resuelto las referencias, vuelvo a ejecutar el proceso
            if (this.referenceResolved) {
              this.firePOIRequests()
            }
            // si ya se han resuelto las ubicaciones, vuelvo a ejecutar el proceso
            if (this.ubicationResolved) {
              this.fireGeoDataRequests()
            }
          })
      } else {
        this.updateModel(true, this.selectorModel.filters)
      }
    },
    /**
     * Incializador del selector
     */
    async initializeSelector () {
      // datos para el selector de actividades
      this.selectorModel.filters[0].selectAction = this.thingFilterCallback.bind(this)
      const thingTypesResponse =
          await thingApi.getThingByTypeKey(this.value.type, 'TRANSPORT')
      const parsedData = thingTypesResponse.data.map(data => { return { id: data._id, name: data.name } })
      this.selectorModel.filters[0].data.cleanAndUpdate(parsedData)
    },
    thingFilterCallback () {
      if (this.selectorModel.filters[0].selectedData.length > 0) {
        this.selectorModel.disableGetReportButton = false
      } else {
        this.selectorModel.disableGetReportButton = true
      }
    },
    /**
     * Modo autoreporte, para obtener y actualizar los datos del reporte
     */
    getDataNotDeferred (refresh = false) {
      this.loadingTable = true
      if (this.loadingSpinner) this.commitVisible({ visible: true })
      const thingRequest = this.selectorModel.filters[0].selectedData
      mapReportApi.getCurrentStateNotDeferred(this.value.type, thingRequest)
        .then(async response => {
          const processedData = await mapCurrentStateService.processReportData(response.data)
          if (!refresh) {
            this.tableHeaders.cleanAndUpdate(processedData.headers)
            this.setSortFunctions()
            this.setTableFilterRules()
          }
          this.tableData.cleanAndUpdate(processedData.data)
          this.loadingTable = false
          if (this.requestCountGeoData > 0) {
            cancelGeoData('cancel geo-data requests')
          }
          if (this.requestCountPOI > 0) {
            cancelPOI('cancel poi requests')
          }
          this.geoDataIndex = -1
          this.poiDataIndex = -1
          this.maxRadius = 20
          this.requestCountGeoData = 0
          this.requestCountPOI = 0
          // limpiar el timeout
          clearTimeout(this.$_debounceTimeoutId)
          this.$_debounceTimeoutId = null
          // si ya se han resuelto las referencias, vuelvo a ejecutar el proceso
          if (this.referenceResolved) {
            this.firePOIRequests()
          }
          // si ya se han resuelto las ubicaciones, vuelvo a ejecutar el proceso
          if (this.ubicationResolved) {
            this.fireGeoDataRequests()
          }
          if (this.loadingSpinner) this.commitVisible({ visible: false })
        })
    },
    setTableFilterRules () {
      this.tableFilterRules = {}
      this.tableHeaders.forEach(header => {
        this.tableFilterRules[header.value] = filterRulesMapped[header.filterType]
      })
    },
    notImplemented () {
      this.showSnackbar({ visible: true, text: this.$t('notImplemented'), timeout: 6000, style: SnackbarStyle.WARNING })
    },
    externalPaginator (pageSize, pageNumber) {
      this.queryPageNumber = Number(pageNumber)
      this.queryPageSize = Number(pageSize)
    },
    setSortFunctions () {
      /*
      Para ordenar las columnas fecha y hora
      TO DO: analizar la posibilidad de incluir este tipo de sorting en el componente genérico
      */
      this.tableHeaders.find(header => header.value === 'positionTimestamp').sort = (a, b) => { return dateSortFunction(a, b, 'DD/MM/YYYY HH:mm:ss') }
      this.tableHeaders.find(header => header.value === 'comunicationTimestamp').sort = (a, b) => { return dateSortFunction(a, b, 'DD/MM/YYYY HH:mm:ss') }
    },
    fireGeoDataRequests () {
      const header = this.tableHeaders.find(h => h.value === 'ubication')
      if (header) {
        header.spinner = true
        header.cellConfig = { spinnerColor: '#03A9F4' }
        header.selected = true
      }
      this.getGeoData()
      this.ubicationResolved = true
    },
    firePOIRequests () {
      const header = this.tableHeaders.find(h => h.value === 'reference')
      if (header) {
        header.spinner = true
        header.cellConfig = { spinnerColor: '#03A9F4' }
        header.selected = true
      }
      this.getPOI()
      this.referenceResolved = true
    },
    getGeoData () {
      this.geoDataIndex++
      if (this.geoDataIndex < this.tableData.length) {
        if (!this.tableData[this.geoDataIndex].ubication) {
          this.geoRequest(this.tableData[this.geoDataIndex], this.geoDataIndex)
        } else {
          this.getGeoData()
        }
      }
    },
    updateModel (showSelector, filters) {
      this.$emit('input', {
        showSelector: showSelector,
        type: this.value.type,
        reportFilters: filters,
        loaded: this.reportLoaded
      })
    },
    getPOI () {
      this.poiDataIndex++
      if (this.poiDataIndex < this.tableData.length) {
        if (!this.tableData[this.poiDataIndex].reference || this.tableData[this.poiDataIndex].reference === '-') {
          this.poiRequest(this.tableData[this.poiDataIndex], this.poiDataIndex)
        } else {
          this.getPOI()
        }
      }
    },
    geoRequest (data, index) {
      this.requestCountGeoData++
      if (this.requestCountGeoData < 10) {
        this.getGeoData()
      }
      geoReferenceApi.getGeoData(data.lat, data.long, this.maxRadius).then(response => {
        this.requestCountGeoData--
        if (this.requestCountGeoData < 10) {
          this.getGeoData()
        }
        const updatedItem = this.tableData[index]
        updatedItem.ubication = response.data.geoLocationData.title + ' - ' + response.data.geoLocationData.subtitle
        this.tableData.splice(index, 1, updatedItem)
      }).catch(() => {
        this.requestCountGeoData--
        if (this.requestCountGeoData < 10) {
          this.getGeoData()
        }
      })
    },
    poiRequest (data, index) {
      this.requestCountPOI++
      if (this.requestCountPOI < 10) {
        this.getPOI()
      }
      geoReferenceApi.getPOI(data.lat, data.long, data.thingId, data.enterpriseId, data.activityId).then(response => {
        this.requestCountPOI--
        if (this.requestCountPOI < 10) {
          this.getPOI()
        }
        const updatedItem = this.tableData[index]
        updatedItem.reference = response.data ? response.data.name : undefined
        this.tableData.splice(index, 1, updatedItem)
      }).catch(() => {
        this.requestCountPOI--
        if (this.requestCountPOI < 10) {
          this.getPOI()
        }
      })
    },
    rowButtonClicked (button, data) {
      switch (button.id) {
        case 1:
          this.addPOILat = parseFloat(data.lat)
          this.addPOILong = parseFloat(data.long)
          this.showAddPOI = true
          break
      }
    },
    closeAddPOIDialog () {
      this.geoDataIndex = -1
      this.getGeoData()
      this.showAddPOI = false
    }
  },
  props: {
    value: Object
  },
  watch: {
    /*
      [EVALUAR SI CORRESPONDE LA APLICACIÓN DE ESTA VERIFICACIÓN]
      Si hay 10 requests activas, activo un temporizador. Si se cumple el tiempo, entonces cancelo
      las requests activas y continuo buscando.
      Este "reseteo" se emplea para evitar que el proceso se quede congelado en requests de las cuales no se obtiene
      una respuesta.
      Cuando hay menos de 10, el proceso continua sin la activación de temporizadores.
    */
    requestCountGeoData () {
      const that = this
      if (this.requestCountGeoData === 10 && that.geoDataIndex < that.tableData.length) {
        clearTimeout(that.$_debounceTimeoutId)
        that.$_debounceTimeoutId = null
        that.$_debounceTimeoutId = setTimeout(() => {
          cancelGeoData('cancel geo-data requests')
          that.requestCountGeoData = 0
          that.getGeoData()
        }, 60000)
      } else if (that.tableData.filter(a => !a.ubication).length > 0) {
        clearTimeout(that.$_debounceTimeoutId)
        that.$_debounceTimeoutId = null
        that.$_debounceTimeoutId = setTimeout(() => {
          cancelGeoData('cancel geo-data requests')
          that.geoDataIndex = -1
          that.requestCountGeoData = 0
          that.getGeoData()
        }, 60000)
      } else {
        clearTimeout(this.$_debounceTimeoutId)
        this.$_debounceTimeoutId = null
      }
    },
    requestCountPOI () {
      const that = this
      if (this.requestCountPOI === 10 && that.poiDataIndex < that.tableData.length) {
        clearTimeout(that.$_debounceTimeoutId)
        that.$_debounceTimeoutId = null
        that.$_debounceTimeoutId = setTimeout(() => {
          cancelPOI('cancel poi requests')
          that.requestCountPOI = 0
          that.getPOI()
        }, 60000)
      } else if (that.tableData.filter(a => !a.reference).length > 0) {
        clearTimeout(that.$_debounceTimeoutId)
        that.$_debounceTimeoutId = null
        that.$_debounceTimeoutId = setTimeout(() => {
          cancelPOI('cancel poi requests')
          that.poiDataIndex = -1
          that.requestCountPOI = 0
          that.getPOI()
        }, 60000)
      } else {
        clearTimeout(this.$_debounceTimeoutId)
        this.$_debounceTimeoutId = null
      }
    }
  }
}
