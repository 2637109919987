import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import i18n from '@/i18n'
import thingApi from '@/api/thing.api'
import pointOfInterestApi from '@/api/pointOfInterest.api'
import { mapActions } from 'vuex'
import { SnackbarStyle } from '@/constants/constants'
import Vue from 'vue'
import { arraysEqual } from '@/tools/functions'

export default {
  name: 'AddPOIDialogComponent',
  components: {
    Treeselect
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    lat: {
      type: Number,
      required: true
    },
    long: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      categories: [],
      selectedVehicles: [],
      selectorData: [],
      selectAll: false,
      vehicleSelectorData: [],
      poi: {
        name: undefined,
        radius: undefined,
        categoryId: undefined
      },
      validationRules: {
        required: (value) => !!value || `${i18n.t('required')}`
      }
    }
  },
  computed: {
    saveDisabled () {
      return !(this.poi.name && this.poi.radius && this.poi.categoryId && this.selectedVehicles.length > 0)
    }
  },
  mounted () {
    this.loadSelectors()
  },
  created () {},
  methods: {
    ...mapActions('snackbar', {
      showSnackbar: 'showSnackbar'
    }),
    closeDialog () {
      this.$emit('closeAddPOIDialog')
    },
    save () {
      this.poi.center = [this.lat, this.long]
      this.setPOIVehicles(this.poi)
      pointOfInterestApi.insert(this.poi)
        .then(() =>
          this.showSnackbar({ visible: true, text: i18n.t('poi.created'), timeout: 6000, style: SnackbarStyle.SUCCESS }))
      this.$emit('closeAddPOIDialog')
    },
    normalizer (node) {
      return {
        id: node.id,
        label: node.name,
        children: node.children
      }
    },
    selectAllChanged () {
      if (this.selectAll) {
        const firstLevelIds = this.selectorData.map(item => this.normalizer(item).id)
        this.selectedVehicles = []
        this.selectedVehicles.cleanAndUpdate(firstLevelIds)
      } else {
        this.selectedVehicles = []
      }
    },
    checkSelectAll () {
      const that = this
      Vue.nextTick(function () {
        const firstLevelIds = that.selectorData.map(item => that.normalizer(item).id)
        firstLevelIds.sort()
        that.selectedVehicles.sort()
        that.selectAll = arraysEqual(firstLevelIds, that.selectedVehicles)
      })
    },
    setPOIVehicles (poi) {
      if (this.selectedVehicles.length > 0) {
        poi.vehicles = this.selectedVehicles
      }
    },
    async loadSelectors () {
      this.categories = (await pointOfInterestApi.getCategories()).data
      this.vehicleSelectorData = (await thingApi.getNested()).data
      this.selectorData = this.vehicleSelectorData
    }
  }
}
